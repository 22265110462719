import React from 'react'
import './style.scss'
const HandArtSvg = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="360 1053 311 383">
                      <defs>
                        <clipPath id="hand-art-2">
                          <path id="Path_877" data-name="Path 877" className="hand-sign-2-1" d="M150.171-209.762a2.138,2.138,0,0,1-.682-2.947L187.2-273.054a14.319,14.319,0,0,1,12.8-7.477,14.351,14.351,0,0,1,12.582,7.862,14.319,14.319,0,0,1-1.109,14.783l-24.526,39.25a2.069,2.069,0,0,1-1.326.978,2.074,2.074,0,0,1-1.622-.3,2.139,2.139,0,0,1-.682-2.947l24.519-39.239a10.053,10.053,0,0,0,.969-10.51,10.076,10.076,0,0,0-8.959-5.6,10.053,10.053,0,0,0-9.022,5.479l-37.708,60.346a2.139,2.139,0,0,1-2.947.679Z" transform="translate(-149.164 280.533)"></path>
                        </clipPath>
                        <clipPath id="hand-art-2-2">
                          <path id="Path_876" data-name="Path 876" className="hand-sign-2-1" d="M-470.036,248.046H1338.277V-768.634H-470.036Z" transform="translate(470.036 768.634)"></path>
                        </clipPath>
                        <clipPath id="hand-art-2-4">
                          <path id="Path_882" data-name="Path 882" className="hand-sign-2-1" d="M141.4-178.518a2.693,2.693,0,0,1-1.044-1.305l-10.274-44.394a23.785,23.785,0,0,1,.829-14.083,12.763,12.763,0,0,1,8.73-8.218A14.6,14.6,0,0,1,156.949-235.3l2.837,12.26a2.152,2.152,0,0,1-.629,2.06,2.155,2.155,0,0,1-2.1.484,2.157,2.157,0,0,1-1.471-1.576l-2.837-12.266a10.318,10.318,0,0,0-12.15-7.99,8.507,8.507,0,0,0-5.74,5.571,19.016,19.016,0,0,0-.626,11.421l10.271,44.382a2.045,2.045,0,0,1-.23,1.652,2.045,2.045,0,0,1-1.384.931,1.3,1.3,0,0,1-1.505-.144Z" transform="translate(-129.386 246.839)"></path>
                        </clipPath>
                        <clipPath id="hand-art-2-7">
                          <path id="Path_887" data-name="Path 887" className="hand-sign-2-1" d="M195.446-214.322a2.075,2.075,0,0,1-.98-1.328,2.07,2.07,0,0,1,.3-1.621l5.785-9.259a10.113,10.113,0,0,0-3.852-12.762,10.113,10.113,0,0,0-13.159,2.132l-5.785,9.259a2.14,2.14,0,0,1-2.949.679,2.14,2.14,0,0,1-.682-2.949l5.785-9.259a14.407,14.407,0,0,1,19.217-3.639,14.407,14.407,0,0,1,5.154,18.867l-5.785,9.259a2.319,2.319,0,0,1-3.044.618Z" transform="translate(-173.798 245.26)"></path>
                        </clipPath>
                        <clipPath id="hand-art-2-10">
                          <path id="Path_892" data-name="Path 892" className="hand-sign-2-1" d="M211.046-205.256a2.074,2.074,0,0,1-.98-1.326,2.07,2.07,0,0,1,.3-1.62l4.832-7.732c2.028-3.245-.219-8.368-4.805-11.234s-10.174-2.642-12.2.606l-4.832,7.732a2.137,2.137,0,0,1-1.333.95,2.141,2.141,0,0,1-1.615-.271,2.074,2.074,0,0,1-.98-1.326,2.07,2.07,0,0,1,.3-1.62l4.832-7.732c3.281-5.25,11.412-6.144,18.1-1.964s9.451,11.88,6.17,17.131L214-205.932a2.067,2.067,0,0,1-1.326.979,2.072,2.072,0,0,1-1.622-.3Z" transform="translate(-189.375 233.43)"></path>
                        </clipPath>
                        <clipPath id="hand-art-2-13">
                          <path id="Path_897" data-name="Path 897" className="hand-sign-2-1" d="M211.923-172.354a2.139,2.139,0,0,1-.682-2.947l15.809-25.3a10.556,10.556,0,0,0-2.717-14.575c-4.969-3.1-10-2.928-11.368-.731l-4,6.4a2.14,2.14,0,0,1-2.949.679,2.14,2.14,0,0,1-.682-2.948l4.051-6.483c2.864-4.583,10.488-4.874,17.271-.635a14.825,14.825,0,0,1,4.082,20.472l-15.808,25.3a2.164,2.164,0,0,1-1.339,1.023,2.169,2.169,0,0,1-1.668-.248Z" transform="translate(-205.012 221.898)"></path>
                        </clipPath>
                        <clipPath id="hand-art-2-16">
                          <path id="Path_902" data-name="Path 902" className="hand-sign-2-1" d="M198.618-156.814,138.794-194.2a2.239,2.239,0,0,1-.778-3.007,2.07,2.07,0,0,1,1.326-.978,2.074,2.074,0,0,1,1.621.3l60.016,37.5a15.286,15.286,0,0,0,20.956-4.826l12.408-19.858a2.07,2.07,0,0,1,1.326-.978,2.074,2.074,0,0,1,1.621.3,2.141,2.141,0,0,1,.951,1.333,2.136,2.136,0,0,1-.27,1.614l-12.408,19.858a19.589,19.589,0,0,1-26.948,6.126Z" transform="translate(-137.727 198.234)"></path>
                        </clipPath>
                        <clipPath id="hand-art-2-0">
                          <rect x="360" y="1053" width="311" height="383"></rect>
                        </clipPath>
                      </defs>
                      <g id="Artboard_1" data-name="Artboard – 1" className="hand-sign-2-2">
                        
                        <g id="Group_1" data-name="Group 1" transform="translate(211.682 869.634)">
                          <g id="Group_1081" data-name="Group 1081" className="hand-sign-2-3" transform="translate(349.662 195.515)">
                            <g id="Group_1080" data-name="Group 1080" className="hand-sign-2-4" transform="translate(-819.698 -646.149)">
                              <path id="Path_875" data-name="Path 875" className="hand-sign-2-5" d="M195.555-291.515,231.1-269.3l-53.913,86.279-35.545-22.211Z" transform="translate(668.098 923.126)"></path>
                            </g>
                          </g>
                          <g id="Group_1083" data-name="Group 1083" className="hand-sign-2-4" transform="translate(-470.036 -450.634)">
                            <g id="Group_1082" data-name="Group 1082" transform="translate(818.86 640.731)">
                              <path id="Path_878" data-name="Path 878" className="hand-sign-2-6" d="M2.166,88.486a2.139,2.139,0,0,1-2.14-2.138V15.191a14.337,14.337,0,1,1,28.623,0V61.474a2.072,2.072,0,0,1-2.14,2.138,2.139,2.139,0,0,1-2.14-2.138V15.2a10.066,10.066,0,1,0-20.059,0V86.363A2.139,2.139,0,0,1,2.172,88.5Z" transform="matrix(0.848, 0.53, -0.53, 0.848, 46.898, 0)"></path>
                            </g>
                          </g>
                          <g id="Group_1085" data-name="Group 1085" className="hand-sign-2-7" transform="translate(323.48 240.119)">
                            <g id="Group_1084" data-name="Group 1084" className="hand-sign-2-4" transform="translate(-793.516 -690.753)">
                              <path id="Path_880" data-name="Path 880" className="hand-sign-2-5" d="M144.61-257.467,192.25-227.7,153-164.88l-47.64-29.769Z" transform="translate(656.349 934.151)"></path>
                            </g>
                          </g>
                          <g id="Group_1087" data-name="Group 1087" className="hand-sign-2-4" transform="translate(-470.036 -450.634)">
                            <g id="Group_1086" data-name="Group 1086" transform="translate(770.826 685.804)">
                              <path id="Path_883" data-name="Path 883" className="hand-sign-2-6" d="M40.917,60.831a2.693,2.693,0,0,1-1.577-.554L7.1,28.073A23.785,23.785,0,0,1,.342,15.691,12.763,12.763,0,0,1,3.391,4.1a14.6,14.6,0,0,1,20.622.338l8.9,8.893a2.155,2.155,0,0,1-3.049,3.046l-8.906-8.9A10.318,10.318,0,0,0,6.423,7.136,8.508,8.508,0,0,0,4.508,14.9,19.016,19.016,0,0,0,10.029,24.92l32.229,32.2a2.045,2.045,0,0,1,0,3.046,1.3,1.3,0,0,1-1.353.676Z" transform="matrix(0.848, 0.53, -0.53, 0.848, 32.238, 0)"></path>
                            </g>
                          </g>
                          <g id="Group_1089" data-name="Group 1089" className="hand-sign-2-8" transform="translate(382.272 242.21)">
                            <g id="Group_1088" data-name="Group 1088" className="hand-sign-2-4" transform="translate(-852.308 -692.844)">
                              <path id="Path_885" data-name="Path 885" className="hand-sign-2-5" d="M187.263-256.245,222.86-234l-20.967,33.554-35.6-22.243Z" transform="translate(676.081 934.547)"></path>
                            </g>
                          </g>
                          <g id="Group_1091" data-name="Group 1091" className="hand-sign-2-4" transform="translate(-470.036 -450.634)">
                            <g id="Group_1090" data-name="Group 1090" transform="translate(851.498 687.423)">
                              <path id="Path_888" data-name="Path 888" className="hand-sign-2-6" d="M26.481,26.327a2.072,2.072,0,0,1-2.14-2.14V13.269a10.118,10.118,0,0,0-20.059,0V24.187a2.141,2.141,0,0,1-4.282,0V13.269a14.414,14.414,0,0,1,28.737,0V24.187a2.319,2.319,0,0,1-2.253,2.138Z" transform="matrix(0.848, 0.53, -0.53, 0.848, 13.952, 0)"></path>
                            </g>
                          </g>
                          <g id="Group_1093" data-name="Group 1093" className="hand-sign-2-9" transform="translate(402.893 257.87)">
                            <g id="Group_1092" data-name="Group 1092" className="hand-sign-2-4" transform="translate(-872.929 -708.504)">
                              <path id="Path_890" data-name="Path 890" className="hand-sign-2-5" d="M200.839-244.058l35.5,22.183-18.946,30.32-35.5-22.183Z" transform="translate(681.132 938.493)"></path>
                            </g>
                          </g>
                          <g id="Group_1095" data-name="Group 1095" className="hand-sign-2-4" transform="translate(-470.036 -450.634)">
                            <g id="Group_1094" data-name="Group 1094" transform="translate(872.146 703.556)">
                              <path id="Path_893" data-name="Path 893" className="hand-sign-2-6" d="M26.476,22.513a2.072,2.072,0,0,1-2.14-2.138V11.258c0-3.826-4.62-6.98-10.028-6.98S4.281,7.429,4.281,11.258v9.118a2.139,2.139,0,0,1-2.14,2.138A2.072,2.072,0,0,1,0,20.376V11.258C0,5.067,6.423,0,14.311,0s14.31,5.067,14.31,11.258v9.118a2.069,2.069,0,0,1-2.14,2.138Z" transform="matrix(0.848, 0.53, -0.53, 0.848, 11.931, 0)"></path>
                            </g>
                          </g>
                          <g id="Group_1097" data-name="Group 1097" className="hand-sign-2-10" transform="translate(423.593 273.136)">
                            <g id="Group_1096" data-name="Group 1096" className="hand-sign-2-4" transform="translate(-893.629 -723.77)">
                              <path id="Path_895" data-name="Path 895" className="hand-sign-2-5" d="M218.45-232.25l35.5,22.184-32.07,51.322-35.5-22.185Z" transform="translate(682.585 942.316)"></path>
                            </g>
                          </g>
                          <g id="Group_1099" data-name="Group 1099" className="hand-sign-2-4" transform="translate(-470.036 -450.634)">
                            <g id="Group_1098" data-name="Group 1098" transform="translate(878.086 719.187)">
                              <path id="Path_898" data-name="Path 898" className="hand-sign-2-6" d="M26.478,47.28a2.139,2.139,0,0,1-2.14-2.138V15.309A10.556,10.556,0,0,0,14.31,4.389c-5.859,0-10.028,2.813-10.028,5.4v7.542a2.141,2.141,0,0,1-4.282,0V9.691C0,4.286,6.311,0,14.31,0a14.825,14.825,0,0,1,14.31,15.2V45.029a2.166,2.166,0,0,1-2.14,2.251Z" transform="matrix(0.848, 0.53, -0.53, 0.848, 25.055, 0)"></path>
                            </g>
                          </g>
                          <g id="Group_1101" data-name="Group 1101" className="hand-sign-2-11" transform="translate(334.522 304.463)">
                            <g id="Group_1100" data-name="Group 1100" className="hand-sign-2-4" transform="translate(-804.557 -755.097)">
                              <path id="Path_900" data-name="Path 900" className="hand-sign-2-5" d="M161.075-231.821l89.59,55.982-30.877,49.414L130.2-182.407Z" transform="translate(664.393 942.455)"></path>
                            </g>
                          </g>
                          <g id="Group_1103" data-name="Group 1103" className="hand-sign-2-4" transform="translate(-470.036 -450.634)">
                            <g id="Group_1102" data-name="Group 1102" transform="translate(803.711 719.755)">
                              <path id="Path_903" data-name="Path 903" className="hand-sign-2-6" d="M72.8,45.027H2.255A2.239,2.239,0,0,1,0,42.89a2.072,2.072,0,0,1,2.14-2.138h70.77a15.286,15.286,0,0,0,15.214-15.2V2.139A2.072,2.072,0,0,1,90.265,0,2.139,2.139,0,0,1,92.4,2.139V25.554A19.589,19.589,0,0,1,72.8,45.03Z" transform="matrix(0.848, 0.53, -0.53, 0.848, 23.862, 0)"></path>
                            </g>
                          </g>
                          <g id="Group_1117" data-name="Group 1117" className="hand-sign-2-4" transform="translate(-470.036 -450.634)">
                            <g id="Group_1114" data-name="Group 1114" transform="translate(371.995 753.555)">
                              <path id="Path_920" data-name="Path 920" className="hand-sign-2-12" d="M500.111,0S244.182,173.706,0,184.748" transform="translate(0 250.055) rotate(-30)"></path>
                            </g>
                            <g id="Group_1115" data-name="Group 1115" transform="translate(445.38 797.509)">
                              <path id="Path_921" data-name="Path 921" className="hand-sign-2-12" d="M500.111,0S244.182,173.706,0,184.748" transform="translate(0 250.055) rotate(-30)"></path>
                            </g>
                            <g id="Group_1116" data-name="Group 1116" transform="translate(785.158 771.655)">
                              <path id="Path_922" data-name="Path 922" className="hand-sign-2-12" d="M0,0,40.938,74.231" transform="translate(0 20.469) rotate(-30)"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
    )
}

export default HandArtSvg;