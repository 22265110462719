import React, { useState, useEffect } from 'react'
import bigbanner from '../../assets/images/big-banner.svg'
import LandingHeader from '../header'
import qmark from '../../assets/images/question-mark.svg'
import './style.scss'
import HandArtSvg from '../../assets/images/hand_art'
import SocialIconLinks from '../../social-links'
import LanguageSwitcher from '../../language_switcher'
import Helmet from 'react-helmet'
import { navigate } from 'gatsby'
import {getLocationData} from '../../location_data'
const windowGlobal = typeof window !== 'undefined' && window
const AreYouScrum = () =>{
  
    const [question,setQuestion] = useState(false)

    const handleScrum=(e)=>{
        if(e ==='mcq'){
        setQuestion(true) 
        }
    }
    return(
        <div className="are_you_scrum">
            <Helmet defer={false}>
                <title>Test how scrum you are | JDSolutions</title>
                <meta property="og:title" content={`Test how scrum you are | JDSolutions`} />
                <meta property="og:type" content="page" />
                <meta property="og:image" content={bigbanner} />  
            </Helmet>
        <LandingHeader bgcolor="#ffffff">
        <p className="en-text" style={{display:'inline-block'}}>Test how scrum you are by JDSolutions</p>
      
        <div className="language-switcher-main desktop-switch">
        <LanguageSwitcher/>
        </div>
        <SocialIconLinks
            classname="social-links"
            facebook={true}
            twitter={true}
            linkedin={true}
            gplus={true}/>
        
        </LandingHeader>
        {!question 
        ?
        <div className="popup-section">
            <div className="popup-innersection en-text" >
                <h2><strong>Scrum vs ScrumBut</strong>Which one are you?</h2>
                <p>How Scrum is your organisation:<br/> the questionnaire that will define the answer</p>
                <button style={{cursor:`pointer`}} className="start-btn" onClick={()=>handleScrum('mcq')}>Start measuring how scrum you are</button>
                <img src={bigbanner} alt="big_banner"/>
            </div> 
        </div>
        :
        <AreYouScrumQuestions/> }
       
        </div>
    )
}
export default AreYouScrum;


const AreYouScrumQuestions =()=>{
    const [activeq,setActiveq] = useState(0)
    const [answered,setAnswered]  = useState(0)
    const [formdata, setFormdata] = useState({})
    const [questions,setQuestions] = useState([
    {   type: [{name:`Yes`,status:false},{name:`No`, status:false}],
        qdata:`Do you have a Scrum Master, Product Owner and Development team in your company?`,
        qdesc:[`Each of these roles in a company has their specific skills and responsibilities, therefore they          are an integral part of the project team in Scrum`,
                `- A Scrum master ensures that the whole team observes the Scrum principles and implements them in the best way during the work process`,
                `- A Product Owner is a single person whose main responsibility is to observe the Product Backlog- management, amendments, tasks progress and completion`,
                `- The Development team is formed of self- organizing members that have all the necessary skills to be cross- functional. The whole team works as a single unit to ensure the product is “Done” by the end of each sprint.`],
        answered:null
        
    },
    {
        type:[{name:`Yes`,status:false},{name:`No`, status:false}],
        qdata:`Does the team organize the work in 4 weeks or less long sprints to deliver working and tested software?`,
        qdesc:[`If you do not work in sprints but follow another approach to organize the teamwork, or if your sprints are longer than 4 weeks, then you misunderstand the real Scrum practice. Longer periods may lead to failure of the development team’s work`],
        answered:null
    },
    {
        type:[{name:`Yes`,status:false},{name:`No`, status:false}],
        qdata:`Do you organize sprint planning meetings before each sprint?`,
        qdesc:[`Sprint planning happens during meetings and it is important part of the Scrum methodology as it gives clarity on what can be delivered during the upcoming sprint`],
        answered:null
    },
    {
        type:[{name:`Yes`,status:false},{name:`No`, status:false}],
        qdata:`Do you use sprint backlogs during the project progress?`,
        qdesc:[`A Sprint backlog is an important concept in Scrum as it contains a detailed plan for delivering the product according to the Sprint’s goal. All newly defined tasks are added in the backlog, all completed tasks are removed, time estimate is recalculated`],
        answered:null
    },
    {
        type:[{name:`Yes`,status:false},{name:`No`, status:false}],
        qdata:`Do you organize daily stand-up meetings (daily Scrums) to discuss the current project aspects and progress?`,
        qdesc:[`Stand- ups are short meetings and they happen on a daily basis to support the teamwork in terms of tasks progress- what has been done in previous day, what will be done today, is there any obstacles for the team members to meet the Sprint goal`],
        answered:null
    },
    {
        type:[{name:`Yes`,status:false},{name:`No`, status:false}],
        qdata:`Do you hold a sprint review at the end of each sprint (usually a four- hour meeting for a month Sprint)?`,
        qdesc:[`A Sprint review is the best way to track how the sprint has passed and what lessons have been learned - how tasks were completed, what issues appeared, including how they were solved, how to proceed with further sprints work`],
        answered:null
    },
    {
        type:[{name:`Yes`,status:false},{name:`No`, status:false}],
        qdata:`Do you organize retrospectives for your team after a sprint review to discuss issues and possible improvement actions?`,
        qdesc:[`A Retrospective gives the opportunity for the whole team to learn from mistakes and create a plan for improvements. Retrospective importance is due to the fact that team roles, progress and tools implemented during the sprint are discussed and improvements to be implemented into the next sprint are considered`],
        answered:null
    },
    {
        type:[{name:`Yes`,status:false},{name:`No`, status:false}],
        qdata:`Is the development team cross- functional, having all necessary skills to create a product?`,
        qdesc:[`Cross- functionality means that even if some of the team members are more qualified on specific tasks (for example frontend vs backend related tasks), still they are changeable in the team and work as a unit for the final result and not as single contributor according to the specific skills they have`],
        answered:null
    }
    ,
    {
        type:[{name:`Yes`,status:false},{name:`No`, status:false}],
        qdata:`Is the number of development team members between 3 and 9?`,
        qdesc:[`There is an optimal number of team members in Scrum to work effectively: 3 to 9. Less than 3 is not enough to work successfully during a sprint and more than 9 members means difficulties in team management`],
        answered:null
    },
    {
        type:[{name:`Yes`,status:false},{name:`No`, status:false}],
        qdata:`Do you have a transparent definition of “Done” that makes possible for every team member to assess their own work as complete?`,
        qdesc:[`If the “Done” definition is not clearly understood by team members, then there is a possibility that they cannot know whether the work they have completed for the product is really finalized. Thus they may slow down the team and eventually it may fail with the project completion`],
        answered:null
    },
    {
        type:[
        {name:`Always`,status:false},
        {name:`Often`, status:false},
        {name:`Sometimes`,status:false},
        {name:`Rarely`,status:false},
        {name:`Never`,status:false}],
        qdata:`How often are the development team members in your organization using the stand-up daily meetings to report their tasks progress to the Scrum Master?`,
        qdesc:[`Each team member should be committed to the team, thus report to the team as a unit, not to the Scrum Master because a member failure means actually a failure of the team and in this case the Scrum Master would be incapable to improve or change things by himself`],
        answered:null
    },
    {
        type:[
        {name:`Always`,status:false},
        {name:`Often`, status:false},
        {name:`Sometimes`,status:false},
        {name:`Rarely`,status:false},
        {name:`Never`,status:false}],
        qdata:`How often does a development team member get ahead of other team members, for example a developer getting ahead of a tester?`,
        qdesc:[`As the planned work needs to be completed by the end of each sprint, all team members need to work in parallel because if just one team member gets ahead of the others, then this might slow down the whole teamwork in the future. In Scrum individual performance ${'doesn`t'} matter because if the whole team struggles to succeed, then every team member fails, even if he has better performed as a single player`],
        answered:null
    },
    {
        type:[
        {name:`Always`,status:false},
        {name:`Often`, status:false},
        {name:`Sometimes`,status:false},
        {name:`Rarely`,status:false},
        {name:`Never`,status:false}],
        qdata:`How often does the development team struggle to meet the compliance documentation initially agreed and experience difficulties to stick to it, thus cannot deploy the software?`,
        qdesc:[`Compliance documentation needs to be observed strictly, which means that all planned tasks for a single sprint need to be completed by the end of the sprint and according to initially agreed compliance criteria specified into documentation`],
        answered:null
    },
    {
        type:[
        {name:`Always`,status:false},
        {name:`Often`, status:false},
        {name:`Sometimes`,status:false},
        {name:`Rarely`,status:false},
        {name:`Never`,status:false}],
        qdata:'How often does a demo failure leads to the team`s discouragement?',
        qdesc:[`A Customer demo failure should be accepted as something constructive for the team as it would rather make the team analyze and consider improvements. Failure is always a change to learn from mistakes and consider and implement improvements`],
        answered:null
    },
    {
        type:[
        {name:`Always`,status:false},
        {name:`Often`, status:false},
        {name:`Sometimes`,status:false},
        {name:`Rarely`,status:false},
        {name:`Never`,status:false}],
        qdata:'How often does the development team feel uncomfortable to risk and try new creative things during the project development?',
        qdesc:[`Every Scrum Master should encourage all the team members to feel free in experimenting for new things without being afraid of failure because accomplishing great success is always related to unconventional and innovative thinking`],
        answered:null
    },
    {
        type:[
        {name:`Always`,status:false},
        {name:`Often`, status:false},
        {name:`Sometimes`,status:false},
        {name:`Rarely`,status:false},
        {name:`Never`,status:false}],
        qdata:'How often does the focus of retrospectives get on a single member accomplishment, rather than the team work progress as a whole?',
        qdesc:[`It is important that the focus is on WHAT has been done by the team, what is its overall approach. Therefore the retrospectives purpose is not to emphasize on a team member who is not good in fulfilling the team expectations because such team member will be distinguished during the process of work and it is not necessary to focus on them during retrospectives`],
        answered:null
    },
    {
        type:[
        {name:`Always`,status:false},
        {name:`Often`, status:false},
        {name:`Sometimes`,status:false},
        {name:`Rarely`,status:false},
        {name:`Never`,status:false}],
        qdata:'How often do you prefer to get the full picture and create a detailed story first, and after that get the development team start to work on the first project sprint?',
        qdesc:[`For Scrum organizations it is never an issue to start a project sprint without the necessary detailed information about what will be done during the whole project. It is a good approach to add the story in the backlog so that the team has all the important details.`],
        answered:null
    },
    {
        type:[
        {name:`Always`,status:false},
        {name:`Often`, status:false},
        {name:`Sometimes`,status:false},
        {name:`Rarely`,status:false},
        {name:`Never`,status:false}],
        qdata:'How often do you focus on the original release plan as initially agreed and not on the backlog and sprint progress?',
        qdesc:[`A Scrum Master must pay general attention to the backlog and sprint completion. In this case the initial release plan with scheduled delivery of product parts remains in the background, otherwise the team effectiveness would be challenged`],
        answered:null
    },
    {
        type:[
        {name:`Always`,status:false},
        {name:`Often`, status:false},
        {name:`Sometimes`,status:false},
        {name:`Rarely`,status:false},
        {name:`Never`,status:false}],
        qdata:'How often do you make management decisions for the development team on work related tasks, which are specific to software implementation and design?',
        qdesc:[`The Management needs to interfere in decisions that are related to budgeting and to alterations of the general project direction and planning. In cases related to design and software implementation it is always better to encourage the team to make their own decisions and with time and practice the team will learn to make the right decisions that cover the management requirements`],
        answered:null
    },
    {
        type:[
        {name:`Always`,status:false},
        {name:`Often`, status:false},
        {name:`Sometimes`,status:false},
        {name:`Rarely`,status:false},
        {name:`Never`,status:false}],
        qdata:'How often do you assign tasks to the development team members according to their role in the team?',
        qdesc:[`Scrum is about self-organizing, which means that a team member makes a decision on what task to work and thus takes the responsibility for the successful task completion. Assigning tasks according to roles is not acceptable as a team member is the one who knows best which task he is fully capable to successfully complete`],
        answered:null
    },
    ])
    const handleAnswer = (e) =>{
        let dfg = windowGlobal.document.getElementById(`fcosd`)
        let oldqd = questions
        if(!e.status){
        oldqd[e.qindex].type.forEach((t,i)=>{
            if(i === e.index){
                t.status = true
            }else{
                t.status = false
            }
        })
        
        oldqd[e.qindex].answered = true
        setQuestions([...oldqd])
        setAnswered(oldqd.filter(el=>el.answered===true).length)
        if(e.qindex < oldqd.length - 1){
        setActiveq(e.qindex+1)           
        dfg.scrollIntoView() 
    }
}
    }
    const handleactiveQuestion = (e) =>{
        let dfg = windowGlobal.document.getElementById(`fcosd`)
       if(e===`down` && activeq < questions.length -1){
            setActiveq(activeq+1)
            dfg.scrollIntoView()
       }else if(e === `up` && activeq > 0){
        setActiveq(activeq-1)
        dfg.scrollIntoView()
       }
      
    }
    useEffect(()=>{

    },[questions,answered,activeq])
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }
    const handleformDataSubmit = (e) =>{
        e.preventDefault()
        let ans = []
        const userrole = []
        const lifecycledata = []
        let phone = null
        let expert = null
        questions.forEach(el => {
            let c = {
                question:el.qdata,
                answer:el.type.filter(ele => ele.status === true)[0].name
            }
            ans.push(c)           
        })
    
        

   
      if(windowGlobal){
          if(windowGlobal.localStorage.getItem('roleData')){
              const t = JSON.parse(windowGlobal.localStorage.getItem('roleData'))
              t.forEach(el=>{
                  if(el.checked === true){
                      userrole.push(el.role)
                  }
              })
          }
           if(windowGlobal.localStorage.getItem('lifecycledata')){
              const s = JSON.parse(windowGlobal.localStorage.getItem('lifecycledata'))
              s.forEach(el=>{
                  if(el.checked === true){
                      lifecycledata.push(el.name)
                  }
              })
          }
          
           if(windowGlobal.localStorage.getItem('number')){
            phone = windowGlobal.localStorage.getItem('number')
          }
           if(windowGlobal.localStorage.getItem('expert_name')){
            expert = windowGlobal.localStorage.getItem('expert_name')
          }
      }
 
  
  fetch("/", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({"form-name": "Scrum data form", 
                    formdata,
                    questiondata: ans,
                    "User_roles": userrole.length !== 0 ? `${userrole.join(", ")}` : null,
                    "Company_lifecycle": lifecycledata.length !== 0 ? `${lifecycledata.join(", ")}` : null,                   
                    "Phone": phone,
                    "Expertise": expert,
                    "Language":getLocationData('languages') !== null ? getLocationData('languages').split(',')[0] : null,
                    "Country":getLocationData('country_name'),
                    "Zip":getLocationData('zipcode'),
                    "User_country":getLocationData('country_name'),
                    "City":getLocationData('city'),
                    "Latitude":getLocationData('latitude'),
                    "Longitude":getLocationData('longitude'),
                    "State":getLocationData('state_prov'),
                  })
    })
      .then(() => {           
        navigate('thank-you-page')
      })
      .catch(error => alert(error));  
  
    }
    const formonChange =(e)=>{
        setFormdata({...formdata,[e.target.name]:e.target.value})
      }
    return(
        <React.Fragment>
            <Helmet defer={false}>
                <html className="smooth-scroll"/>
            </Helmet>
        <div className="main-content customques">
            <div className="question-section start highlight--1">
            <h1 className="en-text" style={{display:'inline-block'}}>Awesome, let's start</h1>
            </div>
            <span className="questions-box">
                {questions.map((dt,index)=>{
                   return(
                    <div                    
                    key={`question${index}`} 
                    className={`question-section question-0 ansable ${index === activeq ? `highlight--1 active` : ``}`}
                    >
                    <h4>
                    <span className="counter">
                    {index+1}
                    <span className="seprator"></span>
                    </span>
                    {dt.qdata}
                    <span className="description" > 
                    <img src={qmark} alt="qmark"/>
                    </span>
                    </h4>
                         
                           
                    <div className={`ans-section ${dt.type.length === 5 ? `mcq-checkes`:``}`}  id={`${index === activeq ? `fcosd` : ``}`}  >
                    {dt.type.length === 2 && 
                    dt.type.map((d,i)=>{
                    return(<span
                    key={`bool${i}`} 
                    style={{cursor:`pointer`}}
                    className={`yn ${d.status ? `active` : ``}`}
                    onClick={()=>handleAnswer({index:i, type:`bool`, status:d.status, qindex:index})}>
                        <span className="mc-test">{d.name}</span>
                    </span>) 
                    })}
                     {dt.type.length === 5 
                    &&
                    dt.type.map((d,i)=>{
                    return(<span 
                    className={`mcq ${d.status ? `active` : ``}`} 
                    key={`rating${i}`}
                    onClick={()=>handleAnswer({index:i,type:`rating`,status:d.status,qindex:index})}>
                        <span className="mcq-in">
                        <span className="circle"></span>
                        <span className="mc-test">{d.name}</span>
                        </span>
                    </span>)
                    })}
                    </div>
                   
                  </div>
                   )
                })}
              
            </span>
            
        </div>
        <footer className="footer-section">
        <div className="container">
            <div className="footer-left-section">
                <span className="noofans en-text" style={{display:`inline-block`}}>{answered} of {questions.length} answered</span>
                <div className="answerbar">
                <div className="fill" style={{width:`${(answered*100)/questions.length}%`}}>
                    </div></div>
            </div> 
            <div className="footer-right-section">
                <span className="arrow-up" onClick={()=>handleactiveQuestion('up')}></span>
                <span className="arrow-down"  onClick={()=>handleactiveQuestion('down')}></span>
            </div> 
        </div>
    </footer>
    
    <NewsletterPopup handleformDataSubmit={handleformDataSubmit} formonChange={formonChange} show={answered === questions.length}/>
    </React.Fragment>
    )
}

const NewsletterPopup =({handleformDataSubmit, formonChange, show})=>{
    return(
        <div className="newsletter-popup" style={{display:show ? `block`:`none`}}>
            <div className="newsletter-inner">
                <h2 className="en-text" style={{display:`inline-block`}}>Want to see your results ?</h2>
                <form 
                className="newsletter-form"
                name="Scrum data form"
                method="POST"
                netlify-honeypot="bot-field"
                data-netlify="true"   
                onSubmit={handleformDataSubmit}            
                >
                    <input type="hidden" name="form-name" value="Scrum data form" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    </p> 
                    <div className="text-box">
                        <input type="email" name={`email`} placeholder="Your email address" onChange={formonChange} required={true}/>
                    </div>
                    <p hidden>
                    <input name="questiondata"/>
                    <input name="User_roles" />
                    <input name="Company_lifecycle" />
                    <input name="Phone" />                  
                    <input name="Expertise" />                   
                    <input name="Language" />
                    <input name="Country" />
                    <input name="Zip" />
                    <input name="User_country" />
                    <input name="City" />
                    <input name="Latitude" />
                    <input name="Longitude" />
                    <input name="State" />
                    </p>
                    <div className="newsletter-error">
                        <span className="en-text" style={{display:`inline-block`}}>
                            Please Enter Your Correct Email
                        </span>                       
                    </div>
                    
                    <input type="submit" value="Send it to me!"/>
                    <HandArtSvg/>
                </form>
            </div>
        </div>
    )
}